import React, { useEffect } from "react";
import "../../css/bootstrap.css"
import "./Footer.css";
import logo from "../../images/Footer/lemlemLogo.jpg";
import { MapPinIcon } from "@heroicons/react/24/solid";
import { ArrowRightCircleIcon } from "@heroicons/react/24/solid";
import { EnvelopeIcon } from "@heroicons/react/24/solid";
import { PhoneIcon } from "@heroicons/react/24/solid";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import TelegramIcon from "@mui/icons-material/Telegram";
import CopyrightIcon from "@mui/icons-material/Copyright";

function Footer() {
  return (
    <footer className="footer-wrapper container-fluid">
      <div className="upper-wrapper d-md-flex">
      <div className="first-wrapper d-sm-flex justify-evenly col-md-9">
      <div className="logo-wrapper col-sm-3">
        <div className="logo d-flex justify-center">
          <a href="/">
            <img className="h-14 lg:h-20 rounded-full" src={logo} />
          </a>

        </div>

        <div>
          {" "}
          <div className="social-media  d-flex justify-center gap-x-2 my-3">
            {" "}
            <a href="#" target="_blank">
              {" "}
              <i className="text-white">
                <FacebookIcon />{" "}
              </i>{" "}
            </a>{" "}
            <a href="#" target="_blank">
              {" "}
              <i className="text-white">
                <InstagramIcon />{" "}
              </i>{" "}
            </a>{" "}
            <a href="#" target="_blank">
              {" "}
              <i className="text-white">
                <TwitterIcon />{" "}
              </i>{" "}
            </a>{" "}
            <a href="https://t.me/LemHS" target="_blank">
              {" "}
              <i className="text-white">
                <TelegramIcon />{" "}
              </i>{" "}
            </a>{" "}
          </div>{" "}
        </div>
      </div>

      <div className="links col-sm-4 mt-4 mt-sm-0">
        <p className=" text-center text-[18px] font-[500] font-serif mb-[10px] lg:text-[20px] lg:font-[600]">
          Go to
        </p>
        <div className="d-flex justify-center">
          <ul className="w-[360px] w-sm-[0px]">
            <li className="d-flex  gap-x-2">
              <ArrowRightCircleIcon className="h-5  lg:h-[30px]"/>
              <a href="/about-us" className="lg:text-[18px] lg:font-[500]">About us</a>
            </li>
            <li className=" d-flex gap-x-2">
              <ArrowRightCircleIcon className="h-5 lg:h-[30px]" />
              <a href="/Achievement" className="lg:text-[18px] lg:font-[500]">Achivement</a>
            </li>
            <li className=" d-flex gap-x-2 ">
              <ArrowRightCircleIcon className="h-5 lg:h-[30px]" />
              <a href="/Gallery" className="lg:text-[18px] lg:font-[500]">Gallery</a>
            </li>
          </ul>
        </div>
      </div>
      
      <div className="officeHour text-center mb-[10px] d-none d-sm-block col-3">
        {" "}
        <h4 className="text-[18px] font-[500] font-serif mb-3 lg:text-[20px] lg:font-[600]">Office hour</h4>
        <p className="text-[14px] lg:text-[15px] lg:font-[500]">Monday to Friday 8:00AM - 5:00PM </p>{" "}
      </div>
      </div>
      <div className="address col-md-3">
        {" "}
        <p className="font-serif text-[18px] font-[500] lg:text-[20px] lg:font-[600] d-flex justify-center mb-3 contact">
          Contact us
        </p>
        <div className="d-flex justify-center">
          <div className="w-[400px]">
            <div className="d-flex justify-center gap-x-2">
              <MapPinIcon className="h-[18px] lg:h-[20px]" />
              <p className="text-[14px] lg:text-[15px] lg:font-[500]">Addis Ababa, Ethiopia</p>
            </div>
            <div className="d-flex justify-center gap-x-2">
              <EnvelopeIcon className="h-[18px] lg:h-[20px]" />
              <p className="text-[14px] lg:text-[15px] lg:font-[500]">lemlemkg@ethionet.et</p>
            </div>
            <div className="d-flex justify-center gap-x-2 pr-[24px] lg:pr-[36px]">
              <PhoneIcon className="h-[18px] lg:h-[20px]" />
              <p className="text-[14px] lg:text-[15px] lg:font-[500]">+251-111-140-074 </p>
            </div>
          </div>
        </div>
      </div>
</div>
<div>
  <p id="para" className="slogan text-[14px] md:text-[18px] mt-4 mb-4 text-center font-mono font-bold">
          A SCHOOL STRIVING TO PROVIDE QUALTY EDUCATION FOR MINMAL FEE
        </p>
</div>
      <div className="copyright mt-3">
        <p className="text-center">
          <span>Copyright 2023</span> ALL RIGHTS RESERVED <CopyrightIcon />
        </p>
      </div>
    </footer>
  );
}
export default Footer;
