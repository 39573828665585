import React, { useEffect } from "react";
// import Content from "./Readmore";
import Mycomponent from "./Mycomponent";
import "./Homepage.css";
import Banner from "../Banner/Banner";

// import ReactCurvedText from "react-curved-text";
import { useState } from "react";
const ReadMore = ({ children }) => {
  const text = children;
  const [isReadMore, setIsReadMore] = useState(true);
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
  };

  return (
    <p className="text">
      {isReadMore ? text.slice(0, 670) : text}
      <span onClick={toggleReadMore} className="read-or-hide">
        {isReadMore ? "...Read more" : " Show less"}
      </span>
    </p>
  );
};


function HomePage() {
  const [showCurve, setShow] = useState(false);

  useEffect(()=>{
    if(window.innerWidth >= 768) setShow(true)
  }, []);
  return (
    <div>
      <section className="home-heghlight-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="left-side-wrapper col-sm-12 col-md-6">
              <Banner />
            </div>
            <div className="right-side-wrapper col-sm-12 col-md-6">
              <div className="right-side-container px-md-[20px]">
                <div className="title-wraper">WELCOME <span className="ml-2">TO </span></div>
                {showCurve ? <Mycomponent /> : <span className="title-wraper">LEMLEM SCHOOL</span>}
                
                <div className="container-fluid story">
                  <p className="background">
                    <ReadMore>
                    The Lemlem School, established in 1996, is dedicated to offering standardized and high-quality education to the local community at affordable fees. With a passionate team of over 153 teachers and administrators, and a student body of more than 1700, the school strives to cultivate self-discipline, academic excellence, and positive social interactions. Over the years, the Lemlem School has made remarkable progress in enhancing the quality of education and services it provides. It has forged enduring partnerships with both governmental and non-governmental institutions, which have played a crucial role in enriching the educational experience for students. These valuable connections grant the school access to essential resources, expertise, networking opportunities, and advocacy efforts. By leveraging these partnerships, the Lemlem School continuously improves its educational offerings, ensuring that students receive the best possible education that prepares them for a bright future.
                       
                    </ReadMore>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="home2-heghlight-wrapper">
        <div className="container-fluid">
          <div className="row">
            <div className="left-side-wrapper col-sm-12 col-md-6">
              {/* <div class="vertical-vission"></div> */}
              <div className="left-side-container mx-[15px]">
                <ul className="">
                  <li className="shadow-lg rounded-md pb-5 mb-8">
                    <h2 className="Vission">VISION</h2>
                    <h4 className="pback">
                      <br />
                    <b>"Empowering Young Minds with Cutting-Edge Education for a Bright Future!"</b> <br/> <br />
                    At our institution, we are dedicated to providing young children with an education that goes beyond the ordinary. We believe in equipping them with the knowledge and skills needed to excel in the ever-evolving realms of science and technology. By integrating the nation's demands, social dynamics, and cultural aspects into our curriculum, we create a holistic learning experience.
                    </h4>
                  </li>
                  {/* <div class="vertical-mission"></div> */}
                  <li className="shadow-lg rounded-md pb-5 pt-0">
                    <h2 className="Mission">MISSION</h2>
                    <h4 className="pback">
                    Our mission is to empower students by providing them with the essential skills and knowledge needed to thrive in their future endeavors, be it higher education or professional pathways. We are committed to enhancing the teaching and learning experience through the integration of modern technology, ensuring our students stay ahead in an ever-evolving world. By instilling values of critical thinking,creativity, and adaptability, we prepare our students to contribute meaningfully to the global community.
                    </h4>
                  </li>
                </ul>
              </div>
            </div>
            <div className="right-side-wrapper col-sm-12 col-md-6">
              <div className="right-side-container"></div>
            </div>
          </div>
        </div>
      </section>
      <section className="flex shadow-lg rounded-md pb-5 my-5 home3-heghlight-wrapper">
        {/* <div class="vertical-row2"></div> */}
        <div className="">
          <h2 className="Objectve"> OBJECTIVE</h2>
          <h4 class="pbacko">
          we are committed to meeting the demands of the government, society, and parents. Our primary goal is to shape capable citizens who not only fulfill their promises but also appreciate and celebrate the rich beauty, unity, and history of Ethiopia. <br />
          Through our professional and dynamic approach, we strive to provide an educational experience that equips students with the skills and knowledge necessary to excel in their personal and professional lives. We believe that by nurturing a deep understanding of Ethiopia's heritage and fostering a sense of unity, we can instill a strong sense of pride and belonging in our students. <br />
          Join us on this remarkable journey as we unlock the potential within each student, empowering them to become active participants in shaping the future of Ethiopia. Together, we will create a generation of accomplished individuals who not only contribute to society but also cherish and preserve our nation's remarkable legacy.
          </h4>
        </div>
        {/* <div class="vertical-row3"></div> */}
      </section>
    </div>
  );
}
export default HomePage;
